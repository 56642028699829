<template>
  <v-row>
    <v-col class="text-center">
      <v-alert
        color="error"
        dark
        class="mb-0 title"
      >
        <div class="d-inline-block sum-title">
          <span v-t="'invoices.paidInTotal'" />
        </div>
        <span class="d-inline-block ml-2">
          {{ '$ ' + paid }}
        </span>
      </v-alert>
    </v-col>
    <v-col
      cols="6"
      class="text-center"
    >
      <v-alert
        color="success"
        dark
        class="mb-0 title"
      >
        <div class="d-inline-block sum-title">
          <span v-t="'invoices.missingPayments'" />
        </div>
        <span class="d-inline-block ml-2">
          {{ '$ ' + missing }}
        </span>
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters('invoicesAndQuotations', [
      'paidInTotalSum',
      'missingPaymentsSum'
    ]),
    paid () {
      return this.paidInTotalSum(this.$route.params.clientId)
    },
    missing () {
      return this.missingPaymentsSum(this.$route.params.clientId)
    }
  }
}
</script>
